<template>
    <div id="body">
        <div class="headerBox">
            <p style="text-align: left;padding-left: 20px;">教育局列表({{ CountNum }})</p>
            <el-button type="primary" class="btn" @click="addVisible = true">+ 添加教育局</el-button>
        </div>
        <div class="query">
            <div class="queryLeft">
                <div>搜索：
                    <el-input class="input" v-model="keyword" size="medium" placeholder="请输入名称"></el-input>
                </div>
                <div>地区：
                    <el-input class="input" v-model="area" size="medium" placeholder="请输入地区"></el-input>
                </div>
                <br>
                <div>类型：
                    <el-select class="select" v-model="typeId" size="medium" placeholder="请选择类型">
                        <el-option v-for="item in typeOptions" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div>所属代理：
                    <el-select class="select" v-model="agencyId" size="medium" placeholder="请选择所属代理">
                        <el-option v-for="item in agencyOptions" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="queryRight">
                <el-button @click="reset()">重置</el-button>
                <el-button type="primary" @click="advList(1)">查询</el-button>
            </div>
        </div>
        <el-table :data="advData" v-loading="loading">
            <el-table-column prop="id" label="教育局ID" align="center" width="80"></el-table-column>
            <el-table-column prop="section_name" label="教育局名称" align="center"></el-table-column>
            <el-table-column label="类型" align="center">
                <template slot-scope="scope">
                    {{ typeMap[scope.row.user_type] }}
                </template>
            </el-table-column>
            <el-table-column prop="account" label="账号" align="center"></el-table-column>
            <el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
            <el-table-column prop="agent_name" label="所属代理" align="center"></el-table-column>
            <el-table-column prop="section_tel" label="联系电话" align="center"></el-table-column>
            <el-table-column label="地区" align="center">
                <template slot-scope="scope">
                    {{ scope.row.province_name }}{{ scope.row.city_name }}
                </template></el-table-column>
            <el-table-column label="操作" align="center" width="250">

                <template slot-scope="scope">
                    <el-link type="primary" :underline="false" @click="onSchool"
                        style="margin-right:10px">查看学校</el-link>
                    <el-link type="primary" :underline="false" @click="onEdit" style="margin-right:10px">编辑</el-link>
                    <el-link type="primary" :underline="false" @click="onFreeze(scope.row.id)" style="margin-right:10px"
                        v-if="scope.row.status == 1">冻结账号</el-link>
                    <el-link type="primary" :underline="false" @click="onFreeze(scope.row.id)" style="margin-right:10px"
                        v-else>解冻</el-link>
                </template>
            </el-table-column>

        </el-table>
        <div class="block">
            <el-pagination @current-change="handleCurrentChange" :current-page="currpage" :page-size="pagesize"
                background layout="total, prev, pager, next, jumper" :total="CountNum" style="margin-top:15px">
            </el-pagination>
        </div>

        <el-dialog title="添加教育局" :visible.sync="addVisible" top="50px" :close-on-click-modal="false">
            <el-form :model="formState" ref="articleForm" label-width="200px">
                <el-form-item label="教育局名称">
                    <el-input v-model="formState.section_name" style="float: left" placeholder="请输入教育局名称" clearable>
                    </el-input>
                </el-form-item>

                <el-form-item label="所属地区">

                </el-form-item>
                <el-form-item label="类型">
                    <el-select class="select" style="float: left" v-model="formState.user_type" size="medium"
                        placeholder="请选择类型">
                        <el-option v-for="item in typeOptions" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属代理">
                    <el-select class="select" style="float: left" v-model="formState.agent_id" size="medium"
                        placeholder="请选择所属代理">
                        <el-option v-for="item in agencyOptions" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="登录账号">
                    <el-input v-model="formState.account" style="float: left" placeholder="请输入登录账号" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="设置密码">
                    <el-input type="password" v-model="formState.password" style="float: left" placeholder="请输入密码"
                        clearable>
                    </el-input>
                </el-form-item>
                <el-form-item label="联系电话">
                    <el-input v-model="formState.section_tel" style="float: left" placeholder="请输入联系电话" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item style="margin-top:5px">
                    <el-button @click="addVisible = false">取消</el-button>
                    <el-button type="primary" @click="onAdd">添加</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>

</template>

<script>
import {
    agentList,
    advList,
    advDetail,
    advExamine,
    eduList,
    ssq,
    eduStatus
} from '@/api/adminApi.js'
export default {
    data() {
        return {
            provinceOptions: [],
            cityOptions:[],
            areaOptions: [],
            addVisible: false,
            formState: {
                section_name: '',
                province_id: '',
                city_id: '',
                area_id: '',
                agent_id: '',
                account: '',
                pass: '',
                section_tel: '',
                user_type: '',
                addr: '',
            },
            agencyOptions: [],
            keyword: '',
            area: '',
            typeId: '',
            agencyId: '',
            typeMap: {
                "1": "市教育局",
                "2": "区教育局",
                "3": "学校",
                "4": "教育集团"
            },
            typeOptions: [{
                value: 1,
                label: '市教育局'
            }, {
                value: 2,
                label: '区教育局'
            }, {
                value: 3,
                label: '学校'
            }, {
                value: 4,
                label: '教育集团'
            }],
            advData: [], // 广告主列表数据
            pagesize: 10, // 每页显示条目个数
            currpage: 1, // 初始页
            CountNum: 0, // 总条数
            loading: false, //加载框
            imgShow: [], //显示的展示图片
            dialogVisible: false, //广告详情弹窗
            data: {}, //广告详情数据
        }
    },
    mounted() {
        this.agencyList()
        this.advList(1) // 获取广告主列表
        this.getProvince()
    },
    methods: {
        getProvince() {
            ssq().then(res=>{
                console.log(res)
                this.provinceOptions = res.data.map()
            })
        },
        onFreeze(id) {
            eduStatus({id}).then(res=>{
                this.$message.success('冻结成功')
                this.advList(1)
            })
        },
        onUnfreeze() {

        },
        onSchool() {

        },
        onEdit() {

        },
        reset() {
            this.keyword = ''
            this.area = ''
            this.typeId = ''
            this.agencyId = ''
            this.advList(1)
        },
        agencyList() {
            agentList({ page: 1, limit: 999 }).then(res => {
                this.agencyOptions = res.data.List.map(item => {
                    return {
                        value: item.agentId,
                        label: item.AgentName
                    }
                })
            })
        },
        advList(currpage) { // 广告主列表
            this.loading = true
            const data = {}
            data["page"] = currpage //	当前页数
            data["limit"] = this.pagesize // 每页条数
            data["section_name"] = this.keyword
            data["user_type"] = this.typeId
            data["agent_id"] = this.agencyId
            data["addr"] = this.area
            eduList(data).then(res => {
                this.CountNum = parseInt(res.data.countNum) // 总条数
                this.advData = res.data.list // 设备列表
                this.currpage = currpage //分页显示当前页
                this.loading = false
            }).catch(err => {
                console.log(err)
            })
        },
        detail(row) { // 点击广告详情
            let data = {
                advId: row.id
            }
            advDetail(data).then(res => {
                this.data = res.data //广告详情数据
                this.imgShow = JSON.parse(res.data.advPic) //将展示图片转换成数组对象
                this.dialogVisible = true //打开弹窗
            }).catch(err => {
                console.log(err)
            })
        },
        check(num) {
            let data = {
                advId: this.data.id, //广告id
                stage: num //审核状态 ( 2通过 3拒绝 )
            }
            advExamine(data).then(res => {
                if (res.status.code == 1) {
                    if (num == 2) {
                        this.$message.success("广告已通过")
                    } else {
                        this.$message.error("广告已拒绝")
                    }
                    this.dialogVisible = false // 关闭弹窗
                    this.advList(1) // 获取广告主列表
                } else {
                    this.$message.error(res.status.msg)
                }
            }).catch(err => {
                console.log(err)
            })
        },
        handleCurrentChange(val) { // 分页
            this.advList(val)
        }
    }
}
</script>
<style lang="scss" scoped>
.headerBox {
    display: flex;
    height: 52px;
    width: 98%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #e9e9e9;

    p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
    }

    .btn {
        display: flex;
        align-items: center;
        width: 125px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
    }
}

.content {
    display: flex;

    p {
        display: flex;
        width: 50%;

        span:nth-child(1) {
            width: 32%;
            text-align: right;
        }

        span:nth-child(2) {
            width: 70%;
            text-align: left;
        }
    }
}

.content-img {
    display: flex;
    justify-content: center;
    gap: 1%;
}

.content-detail {
    span {
        margin-left: 6%;
    }

    div {
        width: 50%;
        margin: 0 auto;
    }
}

.content-range {
    display: flex;

    p {
        display: flex;
        width: 100%;

        span:nth-child(1) {
            width: 16%;
            text-align: right;
        }

        span:nth-child(2) {
            width: 84%;
            text-align: left;
        }
    }
}

.query {
    width: 98%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 32px;

    .queryLeft {
        width: 80%;
        display: flex;

        div {
            width: 22%;
            text-align: left;

            .input,
            .select {
                width: 60%;
            }
        }
    }
}
</style>